<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopupCB">
    <!-- <h6 slot="header">{{header}}</h6> -->
    <div class="session-exp-popup">
      <div class="popup-form">
        <div>
          <img class="image" src="@/assets/icons/tick.svg" alt="tick" loading="lazy"/>
        </div>
        <div>
          <p class="message">{{message}}</p>
        </div>
        <div class="reset-btn">
          <button type="submit" class="btn-login" ref="loginBtnSubmit" @click="actDone">DONE</button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "popSuccess",
  props: {
    closePopupCB: {
      type: Function
    },
    message: {
      type: String
    },
    page:{
      type: String
    }
  },
  data() {
    return {
      width: "35%",
      height: "55%"
    };
  },
  methods: {
    actDone() {
      this.closePopupCB();
      if(this.page){
        this.$router.push({ name: this.page });
      }
    }
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue")
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.session-exp-popup {
  text-align: center;
  .popup-form {
    padding: 5% 26%;
    .image {
      width: 25%;
    }
    .message {
      margin: 15% 0%;
    }
  }
}
</style>